
        @import "variables";
        
.content {
  margin: 16px 0 !important;
  color: #44433D;
}

.titleHead > span:first-child{
  margin-bottom: 8px;
}

.mainTitle {
  display: block;
  margin-bottom: 24px;
  @include themify($themes) {
    color: themed('textColor');
  }
}

.steps {
  display: flex;
  justify-content: space-between;
}

.step {
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #DDE0E5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-weight: 600;
  padding-bottom: 1px;
  z-index: 100;

  &:before {
    content: '';
    width: 96px;
    height: 8px;
    position: absolute;
    right: 25px;
    background-color: #DDE0E5;
  }

  &:first-child:before {
    display: none;
  }

  &__active {
    background-color: #1973E8;

    &:before {
      background-color: #1973E8;
    }
  }
}

.row {
  width: 100%;
  display: inline-flex;
  align-content: space-around;
  margin-bottom: 8px;

  & > div {
    width: 100%;
    margin-right: 24px;
  }

  & > div:last-child {
    margin-right:0;
  }
}

.info{
  margin-bottom: 28px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  color: #393F48;

  img {
    margin: 16px 0 28px;
  }
}
