$themes: (
  light: (
    primaryColor: #1973E8,
    secondaryColor: #b9c4ce,
    textColor: #383f47,
    mainBg: #ffffff,
    secondaryBg: #F8F9FA,
    success: #2FB773,
    reject: #E72D2D,
    gray1: #F6F7F8,
    gray2: #DDE0E5,
  ),
  dark: (
    primaryColor: #1973E8,
    secondaryColor: #b9c4ce,
    textColor: #383f47,
    mainBg: #ffffff,
    secondaryBg: #F8F9FA,
    success: #2FB773,
    reject: #E72D2D,
    gray1: #F6F7F8,
    gray2: #DDE0E5,
  ),
);

/*
 * Implementation of themes
 */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    :global .theme-#{$theme} :local & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
