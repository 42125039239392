
        @import "variables";
        
@import '../../../../styles/mixins.scss';

.change-auth-idp {
  position: relative;

  &__last-cell {
    width: 50px !important;
    max-width: 50px !important;
  }

  &__copy-button {
    cursor: pointer;
    opacity: 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1200px) {
      opacity: 1;
    }
  }

  &__row:hover &__copy-button {
    opacity: 1;
  }

  &__table-loader {
    text-align: center;
  }
}
