
        @import "variables";
        
.change-state-changes {
  position: relative;

  &__description {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.4;
    color: #393F48;

  }

  &__counter {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-button {
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      border-radius: 8px;
      background: #fff;
      border: 1px solid #D0D5DD;
      user-select: none;
      cursor: pointer;

      &:hover:not(.is-disabled) {
        background: #E7F1FF;
        border-color: #1E6AD0;
      }

      &.is-disabled {
        opacity: 0.2;
        cursor: default;
      }
    }

    &-value {
      font-weight: 600;
      font-size: 72px;
      line-height: 1;
      margin: 0 6px;
      min-width: 150px;
      text-align: center;
    }
  }

  &__total {
    border-top: 1px solid #EAECF0;
    border-bottom: 1px solid #EAECF0;
    margin-top: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 20px;

    &-row {
      display: flex;

      & + & {
        margin-top: 12px;
      }
    }

    &-cell {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      font-size: 16px;

      &:first-child {
        color: #101828;
        font-weight: 600;
      }

      &:last-child {
        text-align: right;
        color: #475467;
      }
    }
  }
}