
        @import "variables";
        
.iconButton {  
  box-sizing: border-box;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;

  &.large {
    height: 36px;
    width: 36px;
    min-width: 36px;
    min-height: 36px;
  }

  &.medium {
    height: 32px;
    width: 32px;
    min-width: 32px;
    min-height: 32px;
  }

  &.small {
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  &.micro {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
  }

  &.secondary {
    background-color: #F6F7F8;

    &:hover, &:active {
      svg * {
          fill: #7E8A9A;
      }
  }
  }

  &:hover, &:active {
      svg * {
          fill: #1973E8;
      }
  }

  &.active {
      svg * {
          fill: #1973E8;
      }
  }

  &.disabled {
    cursor: initial;
    svg * {
      fill: #DDE0E5;
  }

    &:hover, &:active {
      cursor: initial;
      svg * {
        fill: #DDE0E5;
    }
    }
  }

  svg * {
      fill: #7E8A9A;
  }
}
