
        @import "variables";
        
.content {
  margin-bottom: 0!important;
}

.titleHead > span:first-child{
  margin-bottom: 8px;
}

.mainTitle {
  display: block;
  margin-bottom: 24px;
  @include themify($themes) {
    color: themed('textColor');
  }
}

.row {
  width: 100%;
  display: inline-flex;
  align-content: space-around;
  margin-bottom: 8px;

  & > div {
    width: 100%;
    margin-right: 24px;
  }

  & > div:last-child {
    margin-right:0;
  }
}

.info{
  margin-bottom: 28px;
}
