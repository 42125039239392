
        @import "variables";
        
@import "../../styles/mixins";

.notification {
  &__items {
    left: 50%;
    transform: translateX(-50%);
    top: 42px !important;

    :global .notify__item__warning {
      border-color: #f8ab03 !important;
      > div {
        background-color: rgba(248,171,3,.09) !important;

        path {
          color: #f8ab03 !important;
        }
      }
    }
  }
}
