
        @import "variables";
        
.custom__banner {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;

    &.orange__color {
        border: 1px solid #F8AB03;
        background: rgba(248, 171, 3, 0.08);
    }

    &__text {
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 1);
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__icon {
        width: 24px;
        height: 24px;
        position: relative;
    }
}