
        @import "variables";
        
.LicenseAttributes {
  position: relative;
  width: 100%;

  &__head {
    color: #6E7F96;
    font-size: 14px;

    &.is-sticky {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #F6F7F8;
      border-bottom: 1px solid #EAECF0;
    }

    &.is-single {
      @media screen and (max-width: 420px) {
        display: none;
      }
    }
  }

  &__body {
    font-size: 14px;
    font-weight: 400;
    color: #7E8A9A;
  }

  &__row {
    min-width: 100%;
    max-width: 100%;
    display: flex;

    &.is-head {
      position: relative;
    }

    &.is-single {
      @media screen and (max-width: 420px) {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    & + & {
      border-top: 1px solid #EAECF0;
    }
  }

  &__cell {
    width: 200px;
    min-height: 44px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-right: 16px;
    box-sizing: border-box;

    &.regular,
    &.highlight,
    &:first-child {
      color: #393F48;
    }

    &.highlight {
      font-weight: 600;
    }

    &:first-child {
      flex: 1;
      width: auto;
    }

    &-value {
      max-width: 100%;
      word-break: break-word;


    }

  }

  &__head & {
    &__cell {
      color: #6E7F96;
    }
  }

  &__row.is-single & {
    &__cell {
      @media screen and (max-width: 420px) {
        height: auto;
        width: auto;
        padding-right: 0;
      }

      &-value {
        @media screen and (max-width: 420px) {
          text-align: right;
        }
      }

      &:first-child {
        @media screen and (max-width: 420px) {
          min-width: 100px;
          padding-right: 10px;
        }
      }
    }
  }
}