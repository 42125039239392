
        @import "variables";
        
.AddLicense {
  position: relative;

  &-container {
    max-height: 305px;
    overflow: auto;

    &.is-diff {
      max-height: 600px;
    }
  }

  &__diff {
    line-height: 20px;
    margin-bottom: 20px;
    min-width: 600px;
  }

  &__tabs {
    display: flex;
    height: 36px;
    width: 100%;
    margin-bottom: 20px;

    &-items {
      display: flex;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #EAECEF;
      border-radius: 4px;
      padding: 4px;
    }

    &-item {
      min-width: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #353945;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
      cursor: pointer;

      &.is-disabled {
        cursor: default;
        opacity: 0.5;
      }

      &.is-active {
        color: #fff;
        background-color: #1973E8;
      }
    }
  }

  &__input {
    width: 100%;
    height: 228px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7F1FF;

    & :global .active {
      background-color: darken(#E7F1FF, 4%)!important;
    }


    &-upload {
      border: 2px dashed #1973E8;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      & > div {
        width: 100%;
        height: 100%;
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
    }

    input {
      width: 100%;
      height: 100%;
    }
  }

  &__preview {
    color: #475467;
    text-align: center;

    &-title {
      font-size: 14px;
    }

    &-desc {
      font-size: 12px;
      margin-top: 4px;
    }

    &-button {
      width: 166px;
      margin-top: 12px;
    }
  }

  &__textarea {
    height: 168px;
    position: relative;

    &-loader {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    &-head {
      display: flex;
    }
    &-init {
      margin-left: 4px;

      path {
        fill: #ACB3BE;
      }
    }

    &-value {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.2;
      color: #7E8A9A;
      margin-top: 4px;
    }

    &-controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &-input,
    &-container {
      width: 100%;
    }

    &-input {
      height: 100%;
      box-sizing: border-box;
      max-height: 168px;
      background-color: #fff;
      padding-top: 0 !important;

      :global .field {
        height: 100%;
        transition: none;

        textarea {
          font-size: 12px;
          font-weight: 600;
          line-height: 1.2;
        }
      }
    }
  }
}