
        @import "variables";
        
.Loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.is-cover {
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10;
  }
}
