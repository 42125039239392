//Mixins
@import 'fonts';

@mixin open-sans {
  font-family: "Open Sans", sans-serif;
}

//Clearfix
@mixin clearfix {

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

//Clearfix end

@mixin main-box {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  min-width: 440px;
  max-width: 440px;

  @media screen and (max-width: 480px) {
    min-width: 0;
    max-width: 100%;
  }
}


@mixin color_el {
  color: #368abb;
}

@mixin color_el_hover {
  color: #26688F;
}

@mixin text-overflow($important: null) {
  overflow: hidden $important;
  text-overflow: ellipsis $important;
  white-space: nowrap $important;
}

@mixin bg_el {
  background-color: #368abb;
}

@mixin bg_el_hover {
  background-color: #26688F;
}

@mixin fill_el {
  fill: #368abb;
}

@mixin stroke_el {
  stroke: #368abb;
}

@mixin border_bt {
  border-bottom: solid 1px #368ABB;
}

@mixin max-z-index {
  z-index: 2147483647;
}

$laptop-width: 1366px;
$tablet-width: 768px;
$desktop-width: 1200px;

@mixin respond-to($device) {
  @if $device ==mobile {
    @media screen and (max-width: #{$tablet-width - 1px}) {
      @content;
    }
  }

  @else if $device ==tablet {
    @media screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
      @content;
    }
  }

  @else if $device ==desktop {
    @media screen and (min-width: #{$desktop-width}) {
      @content;
    }
  }

  @else if $device ==laptop {
    @media screen and (min-width: #{$tablet-width}) and (max-width: #{$laptop-width - 1px}) {
      @content;
    }
  }
}

@mixin no-touch-select {
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: transparent;
  /* prevent tap highlight color */
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
}

@mixin form-field {
  padding-bottom: 0 !important;
  margin-bottom: 16px;

  :global .label {
    color: #393F48 !important;
    font-weight: 600;
  }

  :global .field {
    border-radius: 8px !important;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;

    @include themify($themes) {
      background-color: themed('mainBg');
    }

    ::placeholder {
      color: #ACB3BE;
    }
  }

  // зафиксировать helperText сверху
  &__helperTop {
    >div:nth-child(3) {
      position: absolute;
      top: -3px;
      right: 0;
      margin: 0;
    }
  }
}

@mixin form-field-mobile {
  margin-bottom: 22px;

  &__helperTop {
    >div:nth-child(3) {
      top: 56px;
      left: 1px;
      margin: 4px 0;
    }
  }
}

@mixin box-default($padding: 24px) {
  padding: 40px;
  border-radius: 12px;

  @include themify($themes) {
    background-color: themed('mainBg');
    box-shadow: 0px 1px 3px 0px #1018281A, 0px 1px 2px 0px #1018280F;
    // border: 1px solid themed('gray2');
  }
}

@mixin box-mobile {
  &__header {
    flex-direction: column;
    margin-bottom: 24px;

    &__title {
      margin-bottom: 12px;
    }

    &__action {
      margin-left: 0;
    }
  }

  &__box {
    padding: 20px 16px;
  }
}

@mixin word-wrap($hyphens: false) {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  @if ($hyphens ) {
    hyphens: auto;
  }
}

@mixin divider {
  position: relative;
  min-width: 100%;
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 500;

  &:after,
  &:before {
    content: '';
    height: 1px;
    width: calc(50% - 20px);
    background: #EAECF0;
    position: absolute;
    top: 50%;
    margin-top: -0.5px;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

//Mixins end