
        @import "variables";
        
.editable__avatar {
  cursor: pointer;
  width: fit-content;
  position: relative;

  &.disabled {
    cursor: default;
  }

  &-edit-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    background-color:#1973E8;
    border-radius: 50%;
    margin-right: 4px;

    svg {
      transform: scale(0.7);
      path {
        fill: white;
      }
    }
  }

  &__placeholder {
    width: 28px;
    height: 28px;
  }

  &__upload {
    i {
      width: 12px !important;
    }

    span {
      display: none;
    }

    & > div {
      margin-right: 12px;
    }
  }

  &__replace {
    width: 100%;
    align-items: flex-start !important;
    cursor: pointer !important;

    .editable__avatar__menu__item {
      width: 100% !important;
    }

    &:hover {
      @include themify($themes) {
        background-color: themed('primary60Color');
      }

      .editable__avatar__menu__item {
        @include themify($themes) {
          background-color: themed('primary60Color') !important;
        }
      }
    }

    & > div:first-child {
      width: 100%;
      justify-content: start;

      input {
        left: 0;
      }
    }

    & > div > i {
      display: none;
    }

    & > span {
      display: none;
    }

    input::-webkit-file-upload-button {
      cursor: pointer !important;
    }
  }

  &__menu {
    background-color: #fff;
    z-index: 1000;
    padding: 8px 0;
    width: 220px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(57, 63, 70, 0.1);
    border-radius: 8px;
    @include themify($themes) {
      border: 1px solid themed('gray50');
    }

    &__item {
      transition: background 150ms cubic-bezier(.23, 1, .32, 1) 0ms;

      & > i:nth-child(3) {
        transform: rotate(180deg);
      }
    }

    &__divider {
      @include themify($themes) {
        background-color: themed('gray50') !important;
      }
    }
  }
}
