
        @import "variables";
        
.UserPanel {
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 100%;

  &__user-name {
    position: relative;

    &--single-line {
      max-width: calc(100% - 33px);
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &-name {
    max-width: calc(100% - 33px);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &-photo {
    min-width: 24px;
    min-height: 24px;
    max-height: 24px;
    max-width: 24px;
  }

  &__user-photo {
    min-width: 24px;
    min-height: 24px;

    span {
      margin-right: 0 !important;
    }
  }
}
