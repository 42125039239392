
        @import "variables";
        
$rti-bg: #fff;
$rti-border: #dde0e5;
$rti-main: #1973e8;
$rti-radius: 4px;
$rti-s: 4px;
$rti-s-input: 6px;
$rti-tag: #e7f1ff;
$rti-tag-main: #1973e8;
$rti-tag-remove: #f84545;
$rti-tag-padding: 4px 8px;

.rti {
  &--container {
    position: relative;

    &--disabled,
    &--loading {
      pointer-events: none;
    }

    &--loading {
      opacity: 0.5;
    }

    & * {
      box-sizing: border-box;
    }

    align-items: center;
    background: $rti-bg;
    border: 1px solid $rti-border;
    border-radius: $rti-radius;
    display: flex;
    flex-wrap: wrap;
    gap: $rti-s;
    line-height: 1.4;
    padding: $rti-s-input;
    transition: border-color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transition-delay: 100ms;

    &:focus-within {
      border-color: $rti-main;

      .rti--input--block {
        border-top-color: $rti-main;
      }
    }

    &--block {
      padding: 0;
    }
  }

  &--input {
    border: 0;
    outline: 0;
    font-size: inherit;
    line-height: inherit;
    height: 28px;
    min-width: 50%;
    flex: 1;
    background: none;
    &--block {
      padding: $rti-s-input * 2;
      box-sizing: border-box;
      transition: border-color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      transition-delay: 100ms;
      border-top: 1px solid $rti-border;
      position: relative;
      top: -1px;
      height: 40px;
    }
  }

  &--tag {
    align-items: center;
    background: $rti-tag;
    color: $rti-tag-main;
    border-radius: $rti-radius;
    display: inline-flex;
    justify-content: center;
    padding-left: 8px;
    font-size: 14px;
    line-height: 28px;
    cursor: default;
    max-width: 100%;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 28px);
    }

    &--disabled {
      padding-right: 8px;

      span {
        max-width: 100%;
      }
    }

    button {
      background: none;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      line-height: inherit;
      padding: 0 $rti-s;
      color: $rti-tag-main;
      height: 28px;
      width: 28px;

      &:hover {
        color: $rti-tag-remove;
      }
    }
  }

  &--tags-container {
    max-height: 72px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    gap: $rti-s;
    line-height: 1.4;
    padding: $rti-s-input;
    box-sizing: border-box;
  }
}
