
        @import "variables";
        
.custom__avatar {

  cursor: pointer;
  min-width: 32px;
  min-height: 32px;

  color: #1973E8;
  display: flex;
  position: relative;
  align-items: center;
  font-weight: 400;
  user-select: none;
  border-radius: 50%;
  background-size: cover;
  justify-content: center;
  background-color: #BAD5F8;
  background-repeat: no-repeat;
  background-position: center;

  > span {
    text-transform: capitalize;
  }

  &.xxlarge {
    width: 100px;
    height: 100px;
  }

}
