
        @import "variables";
        
.textButton {
    border: none;
    background-color: inherit;
    padding: 0;
    margin: 0;
    display: inline-block;
    cursor: pointer;
    color: #1973E8;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Open Sans', sans-serif;

    &[disabled] {
        cursor: initial;
        color: grey;
    }

    &.secondary {
        color: #6E7F96;
        font-weight: 600;
    }

    &.inline {
        color: #6E7F96;
        font-weight: 400;
    }
}
