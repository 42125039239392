
        @import "variables";
        
@import "../../../../styles/mixins.scss";

.Workspaces {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  &-preview {
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 100%;
    width: 100%;
    line-height: 2;

    &__image {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;

      span {
        margin-right: 0 !important;
      }
    }

    &__name {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 28px);
    }
  }

  &__header {
    padding-right: 24px;
    padding-left: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 16px;

    &-title {
      line-height: 28px;
      font-weight: 700;
      display: block;
      margin-top: 0;
      font-size: 20px;
    }

    &-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 16px;
    }
  }

  &__body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  &__license {
    max-width: 100%;

    & > div {
      max-width: 100%;
    }

    span {
      margin-right: 0 !important;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 24px;
  }

  &__table-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
      overflow: auto;
    }
  }

  &__user {
    display: flex;
    gap: 8px;
    align-items: center;
    max-width: 100%;

    &-name {
      max-width: calc(100% - 33px);
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-photo {
      min-width: 24px;
      min-height: 24px;
      max-height: 24px;
      max-width: 24px;
    }
  }

  &__user-photo {
    span {
      margin-right: 0 !important;
    }
  }

  &__row-actions {
    display: flex;
    align-items: center;
  }

  &__copy-button,
  &__menu-button {
    cursor: pointer;
    opacity: 0;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1200px) {
      opacity: 1;
    }
  }

  &__table {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
      min-width: 1100px;
    }

    thead,
    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;

      * {
        box-sizing: border-box;
      }

      tr {
        display: flex;
        width: 100%;
      }

      th {
        color: #6E7F96;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;

        &:last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      td {
        &:last-child {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }

      td {
        min-width: calc((100% - 450px) / 3);
        max-width: calc((100% - 450px) / 3);
      }
      th {
        min-width: calc((100% - 450px) / 3);
        max-width: calc((100% - 450px) / 3);
      }

      th,
      td {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        flex-basis: 0;

        &:first-child {
          padding-left: 24px;
        }

        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
          min-width: 100px;
          flex-grow: unset;
          flex-basis: 100px;
        }
        &:last-child {
          min-width: 50px;
          flex-grow: unset;
          flex-basis: 50px;
        }

        @include respond-to(tablet) {
          &:last-child {
            flex-basis: 32px;
          }
        }

        @include respond-to(mobile) {
          &:last-child {
            flex-basis: 32px;
          }
        }

        span {
          margin-right: 12px;
          word-break: break-word;
        }
        .users__small__avatar {
          span {
            margin-right: 0;
          }
        }

        &:last-child {

          span {
            margin-right: 0;
          }
        }
      }
    }

    thead {
      th {
        border-bottom: 1px solid #EAECEF;
        padding: 2px 0 12px 0;
      }
    }

    tbody {
      flex-grow: 1;
      overflow-y: scroll;
      position: relative;

      >* {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
      }


      tr {
        color: #393F48;

        &.row__blocked {
          color: #7E8A9A;
        }

        .controlIcons {
          display: none;
        }

        &:hover {
          background-color: #F0F6FD;

          .controlIcons {
            display: flex;
            flex-direction: row;
            gap: 16px;
          }
        }
      }

      td {
        padding: 15px 6px 14px 0;
        border-bottom: 1px solid #EAECEF;

        &:last-child {
          padding: 18px 0
        }
      }
    }

    &-context {
      position: absolute;
      top: 55px;
      right: 10px;
      z-index: 999999;

      &__reverse {
        bottom: 36px;
        top: unset;
      }
    }
  }

  tr:hover &__copy-button,
  tr:hover &__menu-button {
    opacity: 1;
  }

  &__row {
    position: relative;
  }

  &__truncated {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }

  &__multiline-truncated {
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

.rotated__arrow {
  transform: rotate(180deg);
}