
        @import "variables";
        
.change-rps {
  display: flex;
  flex-wrap: wrap;

  &__item {
    width: calc(33.333% - 16px);
    min-width: calc(33.333% - 16px);
    max-width: calc(33.333% - 16px);
    margin-left: 8px;
    margin-right: 8px;
    height: 80px;
    background-color: #fff;
    border: 1px solid #D8DCEA;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #393F48;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    cursor: pointer;
    position: relative;

    &:hover,
    &.is-active {
      border-color: #1973E8;
    }
  }

  &__item-check {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1973E8;
    display: none;
    align-items: center;
    justify-content: center;

    &.is-active {
      display: flex;
    }
  }

  &__note {
    font-size: 14px;
    font-weight: 600;
    text-align: center;

    &-link {
      color: #1973E8;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}