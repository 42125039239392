
        @import "variables";
        
.content {
  margin: 16px 0 !important;
  color: #44433D;
}

.titleHead > span:first-child{
  margin-bottom: 8px;
}

.mainTitle {
  display: block;
  margin-bottom: 24px;
  @include themify($themes) {
    color: themed('textColor');
  }
}

.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.step {
  box-sizing: border-box;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #DDE0E5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #fff;
  font-weight: 600;
  padding-bottom: 1px;
  z-index: 100;

  &__active {
    background-color: #1973E8;
  }
}

.row {
  width: 100%;
  display: inline-flex;
  align-content: space-around;
  margin-bottom: 8px;

  & > div {
    width: 100%;
    margin-right: 24px;
  }

  & > div:last-child {
    margin-right:0;
  }
}

.info{
  margin-bottom: 28px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  color: #393F48;

  img {
    margin: 16px 0 28px;
  }
}

.qr-code-badges {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin-top: 16px;
  }
}

.qr-code-image-container {
  display: flex;
  gap: 64px;

  &__column {
    position: relative;
    margin-bottom: 16px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;

    svg {
      margin-right: 6px;
    }
  }
}

.qr-code-image {
  background: white;
  border: 10px solid white;
  margin-bottom: 8px !important;
}

.progress-line {
  height: 8px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -4px;
  background: #DDE0E5;
  overflow: hidden;

  &__bar {
    background: #1973E8;
    height: 100%;
    width: 0%;
    transition: width 300ms ease;
  }
}

