
        @import "variables";
        
.modal {
  &__small__avatar {
    min-width: 24px;
    min-height: 24px;
  }

  &__workspace {
    padding: 0 !important;
    max-width: calc(100% - 24px) !important;

    &.large {
      width: 808px;
    }

    &__suggestion__list-no-data {
      padding: 12px 16px;
      color: #7e8a9a;
    }

    &__suggestion__list-loader {
      height: 168px;
      max-height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      background: rgb(255, 255, 255, 0.8);
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      min-height: 56px;
      &.is-solid {
        background: rgb(255, 255, 255, 1);
      }
    }
    &__suggestion__loader {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal__content {
      margin-bottom: 0;
      padding: 20px 0 0;
    }

    .modal__buttons {
      padding: 0;
    }

    .modal__description {
      line-height: 20px;
      margin-bottom: 20px;
      word-break: break-word;
    }

    .modal__checkbox__display__label {
      label {
        display: block;
        flex: unset;
      }
    }

    .modal__checkboxGroup {
      margin-bottom: 20px;
      height: 100%;

      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        position: relative;
        cursor: pointer;
        font-style: italic;
      }

      &__category {
        position: relative;
      }

      &__hidden {
        height: 0;
        overflow: hidden;
      }

      &__icon {
        transform: rotate(0);
        transition: all 0.3s;
        margin-right: -3px;
      }

      &__rotatedArrow {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }

      &__permList {
        padding-left: 12px !important;
      }

      &__checkbox {
        margin: 0;
      }

      li,
      ul {
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      ul {
        list-style-type: none;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &.is-align-top {
        li {
          align-items: flex-start;
        }
      }
    }

    .modal__list__items {
      display: flex;
      flex-direction: column;

      &-scrollable {
        height: 150px;
        overflow: auto;
        margin-bottom: 20px;
      }
      &.modal__list__items-scrollable {
        & > ul {
          flex-grow: 0;
          margin-bottom: 0;
        }
      }
      &-loader {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > ul {
        max-height: 150px;
        overflow-y: scroll;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      li {
        list-style-type: none;
        flex-grow: 1;
      }

      ul {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;
      }

      &__element {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
        border-bottom: 1px solid #dde0e5;

        &__group {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 9px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .modal__list__empty {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
      color: #7e8a9a;
      margin-bottom: 20px;
      padding-bottom: 8px;

      &.has-gap {
        padding: 10px;
      }

      &__danger {
        color: #e72d2d;
      }
    }
  }

  &__fixed-size {
    height: 80%;
    @media screen and (max-height: 580px) {
      min-height: 96%;
    }
  }

  &__overflowVisible {
    overflow: visible !important;
  }

  &__header {
    background-color: #fff;
    padding: 16px 20px;
    position: relative;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    &__close {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__body {
    background-color: #f6f7f8;
    padding: 0 20px 20px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    flex-grow: 1;
    overflow: visible;
    display: flex;
    flex-direction: column;
  }

  &__section {
    margin-top: 20px;
    position: relative;
  }

  &__columns {
    display: flex;

    @media screen and (max-width: 580px) {
      flex-direction: column;
    }

    &-inputs {
      padding-left: 16px;

      @media screen and (max-width: 580px) {
        width: 100%;
        padding-left: 0;
        padding-right: 16px;
        box-sizing: border-box;
      }
    }
  }

  &__upload-image {
    margin-bottom: 20px;
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &-helper {
      color: #7e8a9a;
      font-size: 10px;
      line-height: 16px;
      padding-left: 2px;
      max-width: 540px;
      margin: 8px 0px 0px;
    }
  }

  &__upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-height: 80px;
    margin-bottom: 20px;

    &__img {
      width: 80px;
      height: 80px;
      border: 1px solid #e3e8ec;
      border-radius: 6px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      img {
        max-width: calc(100% - 16px);
        max-height: calc(100% - 16px);
      }
    }

    &__action {
      padding: 8px 0px;
    }

    &__helperText {
      color: #7e8a9a;
      font-size: 12px;
      line-height: 17px;
      padding-left: 2px;
      max-width: 540px;
      margin: 8px 0px 0px;
    }
  }

  &__input,
  &__select {
    padding-top: 8px !important;
    padding-bottom: 20px !important;

    &__label {
      color: #393f48;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;

      &.has-gap {
        margin-bottom: 8px;
      }

      &__description {
        color: #7e8a9a;
        font-weight: 400;

        &--full {
          white-space: normal;
        }
      }

      &__ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    :global .field {
      background-color: #fff;
      padding: 8px 12px !important;
    }

    &__short {
      :global .field {
        padding: 5px 12px !important;
      }
    }

    &__searchItems {
      display: flex;
      position: absolute;
      top: 68px;
      width: 100%;
      background-color: #fff;
      border-radius: 4px;
      padding: 8px 0;
      box-sizing: border-box;
      z-index: 9999;

      &.is-static {
        position: relative;
        top: 0;
        margin-top: 10px;
        margin-bottom: 20px;
        height: 160px;
        padding: 0;

        & > ul {
          max-height: 160px !important;
          overflow: auto;
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        max-height: 152px;
        overflow-y: scroll;
      }

      li {
        flex-grow: 1;
        min-height: 40px;
        padding-left: 16px;
        padding-right: 16px;
        margin: 0;
        font-size: 14px;
        display: flex;
        list-style-type: none;

        &.listItem__disabled {
          opacity: 0.5;

          &:hover {
            cursor: default;
            background-color: inherit;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #f0f6fd;
        }
      }
    }

    &__searchItemControl {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 9px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: relative;

      &-hovered {
        flex-grow: 0 !important;

        &:hover {
          background: #f0f6fd !important;
        }
      }

      > span {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &--selected {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;

        svg {
          path {
            stroke: #1973e8 !important;
          }
        }
      }
    }
  }

  &__select {
    :global .bordered {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    input {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }

  &__workspaces-table {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__content {
    :global .label {
      display: block;
      margin-bottom: 20px;

      > * {
        display: block;
      }
    }

    &.is-static > div {
      display: flex;
    }

    &__row {
      margin-bottom: 20px;

      :global .searchList .label {
        margin-bottom: 0;
      }

      :global .modal__content__chip {
        @include themify($themes) {
          color: themed('docTitleColor');
        }
      }

      :global .error {
        margin-bottom: 0;

        :global .modal__content__chip {
          @include themify($themes) {
            background-color: themed('errorChipColor');
          }
        }

        svg {
          path.fill {
            @include themify($themes) {
              fill: themed('removeColor');
            }
          }
        }
      }
    }

    &--gap {
      & > div {
        padding: 12px 0;
      }
    }
  }

  &__loader {
    position: absolute;
    bottom: 24px;
    left: 4px;
  }

  &__info {
    margin-bottom: 12px;
    display: inline-block;
  }

  &__form {
    & > div {
      margin-bottom: 8px;
    }
  }

  &__btn {
    &.wide {
      width: 100%;
      margin: 0;
    }

    &.cancel {
      @include themify($themes) {
        color: themed('primaryColor');
      }
    }

    &.red {
      cursor: pointer;

      @include themify($themes) {
        background-color: #f84545;
        border: 1px solid #f84545;
        color: #fff;
      }

      &__disabled {
        opacity: 0.5;
        cursor: default;
      }

      &:hover {
        @include themify($themes) {
          background-color: #e63c3c;
          border: 1px solid #e63c3c;
        }
      }
    }
  }

  &__banner {
    padding: 12px 16px !important;

    &__orange {
      background-color: rgba(248, 171, 3, 0.09) !important;
      border: 1px solid rgba(248, 171, 3, 1);

      &:before {
        background-color: transparent !important;
      }
    }
  }

  &__checkbox {
    width: 16px;
    margin-bottom: 0 !important;

    &__partial {
      position: relative;
      color: #1973e8;

      &:after {
        content: '';
        position: absolute;
        left: 25%;
        top: 50%;
        width: 50%;
        height: 2px;
        margin-top: -1px;
        background-color: #fff;
        pointer-events: none;
        user-select: none;
      }

      i {
        color: #1973e8;

        svg {
          border-radius: 2px;
          background-color: #1973e8;

          path {
            fill: #1973e8;
          }
        }
      }
    }
  }

  &__table-body {
    overflow: auto;
    flex: 1;
  }
}

.modal__removeBtn .modal__btn,
.modal__btn.confirm {
  @include themify($themes) {
    background-color: themed('removeColor');
    border-color: themed('removeColor');

    &:hover,
    &:active,
    &:focus {
      background-color: themed('removeColorHover') !important;
      border-color: themed('removeColorHover') !important;
    }
  }
}

.avatar__group__info {
  gap: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &__title {
    color: #393f48;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  &__description {
    color: #7e8a9a;
    font-size: 10px;
    line-height: 1.15;
  }

  &:first-child {
    align-items: center;
    min-width: 100px;
    min-height: 100px;
    position: relative;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 560px) {
  .modal__workspace {
    margin: auto 12px !important;
  }
}
